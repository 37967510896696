import React, { useContext, useEffect, useState, useMemo } from 'react';
import siteMetadata from '../siteMetadata';

export const PageContext = React.createContext({});

export const usePageContext = () => useContext(PageContext);

export const PageContextProvider = ({ pageContext: pageContextProp, children }) => {
  const [pageContext, setPageContext] = useState({
    ...pageContextProp,
    currency: siteMetadata.defaultCurrency,
  });

  const setCurrency = (currency) => {
    setPageContext((previousContext) => ({ ...previousContext, currency }));
  };

  useEffect(() => {
    setPageContext((prevPageContext) => ({ ...prevPageContext, ...pageContextProp }));
  }, [pageContextProp]);

  const value = useMemo(() => {
    return {
      pageContext,
      setCurrency,
    };
  }, [pageContext, setCurrency]);

  return <PageContext.Provider value={value}>{children}</PageContext.Provider>;
};

export default PageContextProvider;
